export const checksums = {
  "content": "v3.3.0--vUkttgYrOJ",
  "resume": "v3.3.0--Vlg0T14SME"
}
export const checksumsStructure = {
  "content": "DjRIbndRGL",
  "resume": "PL6Ga7P7Ep"
}

export const tables = {
  "content": "_content_content",
  "resume": "_content_resume",
  "info": "_content_info"
}

export default {
  "content": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json"
    }
  },
  "resume": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json"
    }
  },
  "info": {
    "type": "data",
    "fields": {}
  }
}